import { ActionReducerMap } from '@ngrx/store';
import { NotificationObject } from './contratos/models/contrato.model';

import { stateGlobalReducer } from '@core/stateGlobal/stateGlobal.reducers';
import { UserSession } from '@core/models/user-roles.model';
import { stateExpedienteReducer } from './expediente/state';
import { FilterDocumentCatalogue, ItemCatalogueExpedienteCar } from '@core/models/expediente-car.model';
import { ProfileType } from '@core/models/profile';

export interface GlobalState {
  listAllCHostCompany?: ProfileType[];
  listCHostAquienPuedeVer?: ProfileType[];
  loadedCarantyhostCRM: boolean;
  loadedBanks: boolean;
  userSession: UserSession;
  loadingGraph?: boolean;
  usersNotificaMC: string[];
  usersAutorizaComisionContrato: string[];
  usersNotificaMCSolicitudContrato: NotificationObject;
  usersNotificaSolicitudPago: NotificationObject;
}

export interface ExpedienteState {
  catalogueDocumentsExpendediente?: ItemCatalogueExpedienteCar[];
  filterDocuments?: FilterDocumentCatalogue;
}

export interface AppState {
  globalState: GlobalState;
  expedienteState: ExpedienteState;
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
  globalState: stateGlobalReducer,
  expedienteState: stateExpedienteReducer,
};
