import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, tap } from 'rxjs';
import { ProfileType } from '@core/models/profile';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0';

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  private groupsSubject = new BehaviorSubject<String[]>([]);
  public groups$ = this.groupsSubject.asObservable();

  private profileSubject = new BehaviorSubject<ProfileType | null>({});
  public profile$ = this.profileSubject.asObservable();

  constructor(protected _http: HttpClient) {}

  getAllUsersCompany() {
    return this._http.get(`${GRAPH_ENDPOINT}/users`);
  }

  public getDirectReportUser(email: string) {
    return this._http.get(`${GRAPH_ENDPOINT}/users/${email}/directReports`);
  }

  public getDirectReportMe() {
    return this._http.get(`${GRAPH_ENDPOINT}/me/directReports`);
  }

  getMeProfile() {
    return this._http.get<ProfileType>(`${GRAPH_ENDPOINT}/me`);
  }

  public getDataMeProfile() {
    return this._http.get(`${GRAPH_ENDPOINT}/me`).pipe(
      tap((response: ProfileType) => {
        this.profileSubject.next(response);
      }),
      tap(() => {
        this.getMyGroups().subscribe((groups: any) => {
          if (groups.value.length > 0) {
            const groupD = groups.value.map((group) => group.displayName);

            this.groupsSubject.next(groupD);
          }
        });
      })
    );
  }

  public getMyGroups() {
    return this._http.get<ResponseApiGraph>(`${GRAPH_ENDPOINT}/me/memberOf`);
  }

  getMembersGroups(id: string) {
    return this._http.get<ResponseApiGraph>(`${GRAPH_ENDPOINT}/groups/${id}/members`);
  }

  public getCarantyHostInter() {
    return this._http.get(`${environment.api_url}/crm/usuarios?backend=azure`);
  }

  public allMyCalendars() {
    return this._http.get(`${GRAPH_ENDPOINT}/me/calendars`);
  }
}

export interface ResponseApiGraph {
  value: unknown;
  '@odata.context': string;
}
