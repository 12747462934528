import { createSelector } from '@ngrx/store';
import { AppState } from '../../app.state';

export const selectExpedienteFeature = (state: AppState) => state.expedienteState;

export const selectCatDcosExpediente = createSelector(
  selectExpedienteFeature,
  (state) => state.catalogueDocumentsExpendediente
);

export const selectFilterDocuments = createSelector(selectExpedienteFeature, (state) => state.filterDocuments);
