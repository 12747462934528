import { UserSession } from '@core/models/user-roles.model';
import { createAction, props } from '@ngrx/store';
import { NotificationObject } from 'src/app/contratos/models/contrato.model';

//ACCION PARA LLAMAR LOS CATALOGOS QUE SE OCUPAN EN CONTRATOS
export const initCatalogueContratos = createAction('[CatalogueContratos API] Load init');

//Accion para llamar catalogos que se ocupan en Calendario
export const initCatalogueCalendario = createAction('[CatalogueCalendario API] Load init');

//ACCIONES PARA DATOS DEL USUARIO EN SESSION
export const initUserSession = createAction('[GRAPH API] Load init');
export const loadSuccessUserSession = createAction(
  '[GRAPH API] Loaded success',
  props<{
    userProfile: UserSession;
    usersNotificaMC: string[];
    usersAutorizaComisionContrato: string[];
    usersNotificaMCSolicitudContrato: NotificationObject;
    usersNotificaSolicitudPago: NotificationObject;
  }>()
);
export const loadErrorUserSession = createAction('[GRAPH API] Loaded error', props<{ errorMessage: string }>());

export const resetStore = createAction('[RESET] STORE');
