import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MenuItem, Grupo } from '../models/roles.model';
import { BehaviorSubject } from 'rxjs';
import { ResponseBackAzure } from '@core/models/responsehttp.model';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  public menuSubject = new BehaviorSubject<MenuItem[]>([]);
  public menu$ = this.menuSubject.asObservable();

  constructor(private _http: HttpClient) {}

  getListMenus(origen: string) {
    return this._http.get(`${environment.api_url}/menu/ruta?menu=${origen}&backend=azure`);
  }

  postMenu(body: MenuItem) {
    return this._http.post(`${environment.api_url}/menu/ruta?backend=azure`, body);
  }

  getByRolUser(origen: string, user: string) {
    return this._http.get(`${environment.api_url}/menu/usuario?nombre_menu=${origen}&user_email=${user}&backend=azure`);
  }

  /*****GRUPOS */
  getListGrupos(origen: string) {
    return this._http.get(`${environment.api_url}/grupos/permisos?origen=${origen}&backend=azure`);
  }

  postAddGroup(body: Grupo) {
    return this._http.post(`${environment.api_url}/grupos/permisos?backend=azure`, body);
  }

  /****ROLEs */
  postAddGrupoToUser(body) {
    return this._http.post(`${environment.api_url}/grupos/usuario/permisos?backend=azure`, body);
  }

  postAddMenuToUser(body) {
    return this._http.post(`${environment.api_url}/menu/permiso/ruta?backend=azure`, body);
  }

  getByRolUserCombineGraph(grupos: string[]) {
    return this._http.post<ResponseBackAzure>(`${environment.api_url}/citas/permisos/graph?backend=azure`, {
      grupos: grupos,
      origen: 'admin',
    });
  }
}
