import { APP_INITIALIZER } from '@angular/core';
import { GlobalApiActions } from '@core/stateGlobal';
import { Store } from '@ngrx/store';

function initializerAuthFactory(store: Store) {
  return async () => await store.dispatch(GlobalApiActions.initUserSession());
}

export const initializerApp = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: initializerAuthFactory,
  deps: [Store],
};
