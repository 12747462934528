import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AppInsightsService } from '@core/services/app-insights.service';
import { SubSink } from 'subsink';
import { GraphService } from '@core/services/graph.service';
import { RolesService } from '@core/services/roles.service';
import { AlertService } from '@core/services/alert.service';
import { ProfileType } from '@core/models/profile';
import { MenuItem } from '@core/models/roles.model';
import { TokenStorageService } from '@core/services/token-storage.service';
@Component({
  selector: 'ic-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private translate: TranslateService,
    private appInsights: AppInsightsService,
    private meta: Meta,
    private _graphService: GraphService,
    private _rolesService: RolesService,
    private _alertService: AlertService,
    private _tokenStorageService: TokenStorageService //private swUpdate: SwUpdate
  ) {
    this.translate.setDefaultLang('es');
    this.translate.use('es');

    //Limpiar cache y unregister service worker PWA
    if ('caches' in window) {
      caches.keys().then(function (keyList) {
        return Promise.all(
          keyList.map(function (key) {
            return caches.delete(key);
          })
        );
      });
    }

    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }
  }

  ngOnInit(): void {
    this.updateMetaHttps();
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    //this.updatePWA();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;

    if (this.loginDisplay) {
      this.subs.add(
        this._graphService.profile$.subscribe((res: ProfileType) => {
          if (res.userPrincipalName) {
            this._rolesService.getByRolUser('admin', res.userPrincipalName).subscribe((res: any) => {
              if (res.code === 200 && res.rol.length > 0) {
                res.rol.sort(function (a, b) {
                  if (a.orden > b.orden) {
                    return 1;
                  }
                  if (a.orden < b.orden) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
                });

                this._rolesService.menuSubject.next(res.rol);
                this._tokenStorageService.savedRol(res.rol.map((rol: MenuItem) => rol.routerLink));
              } else {
                this._alertService.warning('Aún no cuentas con un rol asignado, contacta a tu administrador.');
              }
            });
          }
        })
      );
    }
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService.loginPopup().subscribe((response: AuthenticationResult) => {
        this.authService.instance.setActiveAccount(response.account);
      });
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  updateMetaHttps() {
    //if(this.utilsService.isBrowser){
    const protocol = window.location.protocol.replace(':', '');
    if (protocol === 'http') {
      this.meta.updateTag({
        name: 'cors-https',
        httpEquiv: 'Content-Security-Policy',
        content: 'upgrade-insecure-requests',
      });
    }
    //}
  }

  /*updatePWA() {
    this.swUpdate.versionUpdates
      .pipe(
        filter((evt: any): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        tap((evt: VersionReadyEvent) => {
          console.info(`currentVersion=[${evt.currentVersion?.hash} | latestVersion=[${evt.latestVersion?.hash}]`);
        })
      )
      .subscribe((evt) => {
        console.log('🚀 ~update aviable t:', evt);
        this._alertService
          .confirmAction('Actualización disponible, ¿Deseas actualizar la aplicación?')
          .then((res: any) => {
            if (res.isConfirmed) {
              window.location.reload();
            }
          });
      });
  }*/
}
