import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/app.state';

export const selectStateGlobalFeature = (state: AppState) => state.globalState;
export const selectUserSession = createSelector(selectStateGlobalFeature, (state) => state.userSession);

export const selectUsersNotificaSolicitudPago = createSelector(
  selectStateGlobalFeature,
  (state) => state.usersNotificaSolicitudPago
);
