import { createReducer, on } from '@ngrx/store';
import { GlobalApiActions } from '.';
import { GlobalState } from 'src/app/app.state';

const initialState = {
  state: [],
  showroomsAll: [],
  loadedState: false,
  carantyhostCRM: [],
  listAllCHostCompany: [],
  listCHostAquienPuedeVer: [],
  loadedCarantyhostCRM: false,
  banks: [],
  loadedBanks: false,
  userSession: {},
  loadingGraph: false,
  scopesGraph: [],
  menuGraph: [],
  carsPublish: [],
  usersNotificaMC: [],
  usersAutorizaComisionContrato: [],
  usersNotificaMCSolicitudContrato: {},
  usersNotificaSolicitudPago: {},
  talleresInspeccion: [],
};

export const stateGlobalReducer = createReducer<GlobalState>(
  initialState,

  on(
    GlobalApiActions.loadSuccessUserSession,
    (state, action): GlobalState => ({
      ...state,
      userSession: action.userProfile,
      usersNotificaMC: action.usersNotificaMC,
      usersAutorizaComisionContrato: action.usersAutorizaComisionContrato,
      usersNotificaMCSolicitudContrato: action.usersNotificaMCSolicitudContrato,
      usersNotificaSolicitudPago: action.usersNotificaSolicitudPago,
      loadingGraph: true,
    })
  )
);
