import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from '@core/services/alert.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorBackService {
  constructor(private injector: Injector) {}

  public handleError(error: HttpErrorResponse) {
    let errorMessage: string;

    if (error instanceof HttpErrorResponse) {
      const alertService = this.injector.get(AlertService);

      // The backend returned an unsuccessful response code.
      let statusCode = error.status;

      switch (statusCode) {
        case 400:
          errorMessage = `Solicitud incorrecta.`;
          break;
        case 401:
          errorMessage = `Necesita iniciar sesión para realizar esta acción.`;
          break;
        case 403:
          errorMessage = `No tienes permiso para acceder al recurso solicitado.`;
          break;
        case 404:
          errorMessage = `El recurso solicitado no existe.`;
          break;
        case 412:
          errorMessage = `Condición previa Falló.`;
          break;
        case 500:
          errorMessage = `Error de servidor interno.`;
          break;
        case 503:
          errorMessage = `El servicio solicitado no está disponible.`;
          break;
        case 422:
          errorMessage = `¡Error de validacion!`;
          break;
        default:
          errorMessage = `¡Algo salió mal!`;
      }

      //TODO: Descomentar esta linea para informar errores del server
      alertService.warning(errorMessage, `Error ${statusCode} en el Servidor.`);

      console.error(`Error ${statusCode} en el Servidor.`);
    }
  }
}
