import { Pipe, PipeTransform } from '@angular/core';
import { formatDateCustom } from '@shared/utils/utils';

@Pipe({
  name: 'formatDateCustom',
  standalone: true,
})
export class FormatDateCustomPipe implements PipeTransform {
  transform(stringDate: string, formatDate: string): string {
    return formatDateCustom(stringDate, formatDate);
  }
}
