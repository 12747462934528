import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  readonly BASE_URL: string = `${environment.api_url}`;

  constructor(private http: HttpClient) {}

  getPayProcessComprador() {
    return this.http.get(`${this.BASE_URL}/user/rooms/getPendingBuyersPayment`);
  }

  getPayProcessVendedor() {
    return this.http.get(`${this.BASE_URL}/user/rooms/getPendingSellersPayment`);
  }

  approvePyment(idPago: string, type: string) {
    return this.http.put(`${this.BASE_URL}/user/update/payment`, JSON.stringify({ id: idPago, user: type }));
  }

  getDetailPayment(idPayment: string) {
    return this.http.get(`${this.BASE_URL}/payments/getDetailPayment/${idPayment}`);
  }

  //Nueva version ---> se convierte en contratos/ventas

  //Solicitud que realiza el pago de un auto para darlo de baja
  closeTransaction(bodyPut) {
    return this.http.put(`${this.BASE_URL}/cars/transactions/closeTransaction`, JSON.stringify(bodyPut));
  }
}
