import { createReducer, on } from '@ngrx/store';
import { ExpedienteState } from 'src/app/app.state';
import { ExpedienteApiActions } from '.';

const initialState = {
  catalogueDocumentsExpendediente: [],
  filterDocuments: null,
};

export const stateExpedienteReducer = createReducer<ExpedienteState>(
  initialState,
  on(
    ExpedienteApiActions.loadSuccessCatalogueExpedienteDocs,
    (state, action): ExpedienteState => ({
      ...state,
      catalogueDocumentsExpendediente: action.catalogueDocumentsExpendediente,
    })
  ),
  on(
    ExpedienteApiActions.loadFilterDocuments,
    (state, action): ExpedienteState => ({
      ...state,
      filterDocuments: action.filterDocuments,
    })
  )
);
