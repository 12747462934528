import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalGuard } from '@azure/msal-angular';
import { RolUserGuard } from '@core/guards/rol-user.guard';
import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'admin',
    component: LayoutComponent,
    canActivate: [MsalGuard],
    children: [
      { path: '', component: HomeComponent, canActivate: [MsalGuard] },
      {
        path: 'autos',
        loadChildren: () => import('./autos/autos.module').then((m) => m.AutosModule),
        canActivate: [MsalGuard, RolUserGuard],
      },
      {
        path: 'payment',
        loadChildren: () => import('./payment-contratos/payment.module').then((m) => m.PaymentModule),
        canActivate: [MsalGuard, RolUserGuard],
      },
      {
        path: 'reembolso',
        loadChildren: () => import('./reembolsos/reembolsos.module').then((m) => m.ReembolsosModule),
        canActivate: [MsalGuard, RolUserGuard],
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),
        canActivate: [MsalGuard, RolUserGuard],
      },
      {
        path: 'referrals',
        loadChildren: () => import('./referrals/referrals.module').then((m) => m.ReferralsModule),
        canActivate: [MsalGuard, RolUserGuard],
      },
      {
        path: 'prensa',
        loadChildren: () => import('./prensa/prensa.module').then((m) => m.PrensaModule),
        canActivate: [MsalGuard, RolUserGuard],
      },
      {
        path: 'conceptos',
        loadChildren: () => import('./conceptos/conceptos.module').then((m) => m.PaymentReferencesModule),
        canActivate: [MsalGuard, RolUserGuard],
      },
      {
        path: 'new-concepto',
        loadChildren: () => import('./new-concepto/new-concepto.module').then((m) => m.NewConceptoModule),
        canActivate: [MsalGuard, RolUserGuard],
      },
      {
        path: 'privilegios',
        loadChildren: () => import('./privilegios/privilegios-roles.module').then((m) => m.PrivilegiosRolesModule),
        canActivate: [MsalGuard, RolUserGuard],
      },
      {
        path: 'contratos',
        loadChildren: () => import('./contratos/contratos.module').then((m) => m.ContratosModule),
      },
    ],
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '**',
    loadChildren: () => import('./page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      // enableTracing: true,  // <-- debugging purposes only
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledBlocking' : 'disabled', // Remove this line to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
