export const environment = {
  production: false,
  api_url: 'https://api-dev.caranty.com/caranty-dev-v2',
  key_token: 'b799ad6c0285497f80f05fdf830c98f4',
  header_api_key: 'api-key',
  backen_azure: {
    header: 'api-key',
    key: 'b799ad6c0285497f80f05fdf830c98f4',
    code: 'code',
    keycode: 'IeITEeTN7UQzHPbjTGoO139sWRnEKZCb5N7F36p4guUiwbvMt0mmaA==',
  },
  msalAuth: {
    graphEndpoint: 'https://graph.microsoft.com/v1.0',
    clientId: 'b9b72191-d45e-41f8-b790-459796d68fa6',
    authority: 'https://login.microsoftonline.com/e0ee65fd-9f80-4118-99ca-6784aa65d6e1',
    scopes: [
      'user.read',
      'User.ReadBasic.All',
      'Directory.Read.All',
      'Calendars.Read',
      'Calendars.ReadBasic',
      'Calendars.ReadWrite',
      'Calendars.Read.Shared',
      //'CustomSecAttributeDefinition.Read.All',
      //'CustomSecAttributeAssignment.Read.All',
      'User.Read.All',
      'Application.Read.All',
    ],
  },
  appInsights: {
    instrumentationKey: 'f82346a1-23fa-47de-b16a-077be1531a47',
  },
};
