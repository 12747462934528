import { Injectable, inject } from '@angular/core';
import { AlertService } from '@core/services/alert.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, combineLatest, firstValueFrom } from 'rxjs';
import { map, catchError, tap, concatMap, switchMap } from 'rxjs/operators';
import { GlobalApiActions } from '.';
import { GraphService } from '@core/services/graph.service';
import { MenuItem, UserSession } from '@core/models/user-roles.model';
import { RolesService } from '@core/services/roles.service';
import { NotificationObject } from 'src/app/contratos/models/contrato.model';
import { StoreLocalStorageAuthService } from '@core/services/store-localStorage-auth.service';
import { ProfileType } from '@core/models/profile';
import { deleteDuplicateArrayObjects } from '@shared/utils/utils';

@Injectable()
export class StateGlobalEffects {
  private readonly _notificationsService = inject(AlertService);

  private readonly _graphService = inject(GraphService);
  private readonly _rolService = inject(RolesService);
  private readonly _storeAuthLocalStorage = inject(StoreLocalStorageAuthService);

  constructor(private actions$: Actions) {}

  GET_USER_PROFILE$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initUserSession),
      concatMap(() =>
        combineLatest([
          this._graphService.getMeProfile(),
          this._graphService.getMyGroups(),
          this._graphService.getMembersGroups('82c98979-7735-4afb-9cb0-9f7f9f92f2ab'),
          this._graphService.getMembersGroups('a3d1f3a4-29d5-4da6-96fb-5d6a6a6b2e34'),
          this._graphService.getMembersGroups('ba99fe70-c730-491d-b47c-0784cfca7eb6'),
          this._graphService.getMembersGroups('0f0dd293-8a4a-43b8-b42d-52bdd36aa631'),
        ]).pipe(
          map(
            ([
              profile,
              groups,
              _usersNotificaMC,
              _usersNotificaAutComision,
              _notificaMCSolContrato,
              _notificationSolicitudContrato,
            ]) => {
              const userProfile: UserSession = {};
              let usersAutorizaComisionContrato: string[] = [];
              let usersNotificaMC: string[] = [];
              let usersNotificaMCSolicitudContrato: NotificationObject = {};
              let usersNotificaSolicitudPago: NotificationObject = {};

              if (profile) {
                userProfile.profileGraph = profile as ProfileType;

                const profileToken = {
                  uniqueId: profile.id,
                  username: profile.userPrincipalName,
                  name: profile.displayName,
                  environment: 'login.app.web',
                };

                usersNotificaMCSolicitudContrato.hostEmail = [profile.userPrincipalName];
                usersNotificaMCSolicitudContrato.hostNumber = [`${profile.mobilePhone}`];

                userProfile.profileToken = profileToken;
              }

              if (Array.isArray(groups.value) && groups.value.length > 0)
                userProfile.groupsGraph = groups.value.map((group) => group.displayName);

              if (Array.isArray(_usersNotificaMC.value) && _usersNotificaMC.value.length > 0)
                usersNotificaMC = _usersNotificaMC.value
                  .filter((user) => user.mobilePhone)
                  .map((user) => user.mobilePhone);

              if (Array.isArray(_usersNotificaAutComision.value) && _usersNotificaAutComision.value.length > 0)
                usersAutorizaComisionContrato = _usersNotificaAutComision.value
                  .filter((user) => user.mobilePhone)
                  .map((user) => user.mobilePhone);

              if (Array.isArray(_notificaMCSolContrato.value) && _notificaMCSolContrato.value.length > 0) {
                usersNotificaMCSolicitudContrato = {
                  ...usersNotificaMCSolicitudContrato,
                  mesa_controlEmail: _notificaMCSolContrato.value.map((user) => user.userPrincipalName),
                  mesa_controlNumber: _notificaMCSolContrato.value
                    .filter((user) => user.mobilePhone)
                    .map((user) => user.mobilePhone),
                };
              }

              if (
                Array.isArray(_notificationSolicitudContrato.value) &&
                _notificationSolicitudContrato.value.length > 0
              ) {
                usersNotificaSolicitudPago = {
                  ...usersNotificaSolicitudPago,
                  mesa_controlEmail: _notificationSolicitudContrato.value.map((user) => user.userPrincipalName),
                  mesa_controlNumber: _notificationSolicitudContrato.value
                    .filter((user) => user.mobilePhone)
                    .map((user) => user.mobilePhone),
                };
              }

              return {
                userProfile,
                usersAutorizaComisionContrato,
                usersNotificaMC,
                usersNotificaMCSolicitudContrato,
                usersNotificaSolicitudPago,
              };
            }
          ),
          switchMap(
            async ({
              userProfile,
              usersAutorizaComisionContrato,
              usersNotificaMC,
              usersNotificaMCSolicitudContrato,
              usersNotificaSolicitudPago,
            }) => {
              const grupos = userProfile.groupsGraph ?? [];
              //const grupos = ['Usuario - CRM', 'Caranty'];

              const scopes = await firstValueFrom(this._rolService.getByRolUserCombineGraph(grupos));

              return {
                userProfile,
                scopes,
                usersAutorizaComisionContrato,
                usersNotificaMC,
                usersNotificaMCSolicitudContrato,
                usersNotificaSolicitudPago,
              };
            }
          ),

          map(
            ({
              userProfile,
              scopes,
              usersAutorizaComisionContrato,
              usersNotificaMC,
              usersNotificaMCSolicitudContrato,
              usersNotificaSolicitudPago,
            }) => {
              if (scopes.code === 200 && Array.isArray(scopes.result) && scopes.result.length > 0) {
                scopes.result.sort(function (a, b) {
                  if (a.orden > b.orden) {
                    return 1;
                  }
                  if (a.orden < b.orden) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
                });

                userProfile.menuGraph = deleteDuplicateArrayObjects(scopes.result) as MenuItem[];

                userProfile.scopesGraph = userProfile.menuGraph.map((rol: MenuItem) => rol.routerLink) as string[];

                userProfile.menuGraph = userProfile.menuGraph.filter((rol: MenuItem) => rol.visibleMenu);

                this._storeAuthLocalStorage.setScopesUser(userProfile.scopesGraph);
              }

              return GlobalApiActions.loadSuccessUserSession({
                userProfile,
                usersNotificaMC,
                usersAutorizaComisionContrato,
                usersNotificaMCSolicitudContrato,
                usersNotificaSolicitudPago,
              });
            }
          ),
          catchError(() => EMPTY)
        )
      )
    );
  });

  notifyApiError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GlobalApiActions.loadErrorUserSession),
        tap((action) => this._notificationsService.error(action.errorMessage))
      );
    },
    { dispatch: false }
  );
}
